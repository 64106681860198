import React, { memo, useReducer } from "react";

const LottiePLayer = React.lazy(() => import("./lottiePlayer"));

const HeaderAnimation = () => {
  const isSSR = typeof window === "undefined";

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div></div>}>
          <LottiePLayer></LottiePLayer>
        </React.Suspense>
      )}
    </>
  );
};

export default memo(HeaderAnimation);
