import React, { useEffect, useReducer, useState } from "react";
import { graphql } from "gatsby";

import { isMobile } from "react-device-detect";

import HeaderAnimation from "../components/headerAnimation";
import LetterFromEditor from "../components/letterFromEditor";
import Layout from "../components/Layout";
import { ProgressRing } from "../components/progressbar";
import Section from "../components/Section";

import { init_pointer } from "../components/pointer";
import "../styles/pointer.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import { SectionContext } from "../utils/sectionContext.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

const scrollReducer = (state, action) => {
  switch (action.type) {
    case "section-index": {
      return { ...state, pageIndex: action.pageIndex };
    }
    default: {
      return state;
    }
  }
};
const initialState = {
  pageIndex: "one",
};

export const IndexPageTemplate = ({ sections }) => {
  const [curPos, dispatchPos] = useReducer(scrollReducer, initialState);

  useEffect(() => {
    if (!isMobile) init_pointer();
  }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  
}, []);

    if (loading)
     return  <div style={{
       height: `100vh`,
       display: `flex`,
       justifyContent: `center`,
       alignItems: `center`
           }}><Loader  type="Puff" color="#00BFFF" height={100} width={100}timeout={2000} //3 secs

     /></div> 

  return (
    <SectionContext.Provider value={{ curPos, dispatchPos }}>
      <div className='flex flex-col bg-home-page'>
        <div
          className='min-h-screen h-screen w-screen z-20'
          style={{ overflowX: "clip" }}
        >
          <HeaderAnimation></HeaderAnimation>
        </div>
        {/* Progress bar */}
        {/* {!isMobile ? (
          <ProgressRing className='fixed' radius={40} stroke={2} />
        ) : null} */}
        <LetterFromEditor></LetterFromEditor>
        <div className='w-full'>
          {Object.entries(sections).map(([sectionKey, sectionData]) => {
            return (
              <Section sectionKey={sectionKey} posts={sectionData}></Section>
            );
          })}
        </div>
      </div>
    </SectionContext.Provider>
  );
};

const IndexPage = ({ data }) => {
  const { nodes } = data.allMarkdownRemark;

  const posts = nodes.filter((node) => node.frontmatter.section);

  const sections = {};

  for (const post of posts) {
    if (post.frontmatter.section in sections) {
      sections[post.frontmatter.section].push(post);
    } else {
      sections[post.frontmatter.section] = [post];
    }
  }

  const sortedSections = {
    one: sections.one,
    two: sections.two,
    three: sections.three,
    four: sections.four,
    five: sections.five,
  };

  return (
    <Layout isHome={true}>
      <IndexPageTemplate sections={sortedSections} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    allMarkdownRemark {
      nodes {
        fields {
          slug
        }
        id
        frontmatter {
          title
          section
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 2048, maxHeight: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          author
        }
      }
    }
  }
`;
