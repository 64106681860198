import React from "react";

export const LetterFromEditor = () => {
  return (
    <>
      <div className='flex w-screen mb-8' style={{ height: "120vh" }}>
        <div class='max-w-7xl h-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center'>
          <div class='max-w-6xl mx-auto text-xl leading-relaxed subpixel-antialiased'>
            <p className='text-center text-5xl  bevan text-black'>
              Letter from the editors
            </p>
            <p>
              For many of us, 2020 is shaping up to be a year we want to forget
              but never can. We have endured a pandemic, participated in a
              reckoning of social and cultural systems, and spent a semester
              streaming lectures online by ourselves. This has all felt bigger
              than us. As we sit in our childhood bedrooms and open the window
              now and again for some fresh air, it is common to feel alone and
              uncertain.
            </p>
            <p>
              This magazine’s theme came from that exact feeling. In a time when
              everything seems so out of our control, how can we push thumbtacks
              into this world and try to pin it down? How can we find our own
              access points to begin to understand what’s happening both around
              us and to us?
            </p>
            <p>In short, how can we deconstruct what we know to be true?</p>
            <p>
              A curious observation from the pieces in this issue is that most
              are personal essays. I have a hypothesis about this. Especially
              during the COVID-19 pandemic, considering the isolation that
              lockdown measures and staying home have imposed on people, we have
              all turned inward this year. We have become more self-reflective
              because the person we’ve been spending the most time with is
              ourselves.
            </p>
            <p>
              To all readers and viewers, I hope you find your own access points
              to understand and process the past year with these pieces. Whether
              it be through a door, a window, or even a crack in the wall, I
              hope that this magazine will give you even a sliver of light.
            </p>
            <p>Here’s to a new year, a vaccine, and silver linings. Enjoy.</p>
            <p className='italic'>
              — Stephanie Bai, Magazine Editor, and Nathalie Whitten, Creative
              Director
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LetterFromEditor;
