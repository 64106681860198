const getSectionPoetry = (sectionKey) => {
  const sectionData = {
    one: {
      roman: "I.",
      lineOne: "I. Somewhere, at a 4:00 pm sunset,",
      lineTwo: "a chrysanthemum dies and decomposes.",
      photoOne: "/img/poetry/sectionOneImageOne.jpg",
      photoTwo: "/img/poetry/sectionOneImageTwo.jpg",
    },
    two: {
      roman: "II.",
      lineOne: "II. In student residence, an iPhone lights up —",
      lineTwo: "scattering likes on an Instagram post. ",
      photoOne: "/img/poetry/sectionTwoImageOne.jpg",
      photoTwo: "/img/poetry/sectionTwoImageTwo.jpg",
    },
    three: {
      roman: "III.",
      lineOne: "III. Bundled strangers pass each other on Toronto streets, ",
      lineTwo: "Footprints loud on a sheet of fresh snow.",
      photoOne: "/img/poetry/sectionThreeImageOne.jpg",
      photoTwo: "/img/poetry/sectionThreeImageTwo.jpg",
    },
    four: {
      roman: "IV.",
      lineOne: "IV. New growth!",
      lineTwo: "A small sprout emerges from a crack in the pavement.",
      photoOne: "/img/poetry/sectionFourImageOne.jpg",
      photoTwo: "/img/poetry/sectionFourImageTwo.jpg",
    },
    five: {
      roman: "V.",
      lineOne: "V. Somewhere, at a 5:00 am sunrise, ",
      lineTwo: "a chrysanthemum blooms.",
      photoOne: "/img/poetry/sectionFiveImageOne.jpg",
      photoTwo: "/img/poetry/sectionFiveImageTwo.jpg",
    },
  };
  if (sectionKey in sectionData) {
    return sectionData[sectionKey];
  } else {
    return sectionData["one"];
  }
};

export { getSectionPoetry };
