import React from "react";
import { Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import { Link } from "gatsby";

import BackgroundImage from "gatsby-background-image";

const Post = ({ post, index }) => {
  const { frontmatter } = post;

  const { fields } = post;

  const { slug } = fields;

  const { title, author, featuredimage } = frontmatter;

  return (
    <Slide index={index}>
      <div className='w-full flex flex-col justify-center items-center'>
        <div className='flex w-full items-center'>
          <div className='w-1/4 flex justify-center'>
            <ButtonBack>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='w-12 h-12 text-white'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M10 19l-7-7m0 0l7-7m-7 7h18'
                />
              </svg>
            </ButtonBack>
          </div>
          <div className='w-1/2'>
            <BackgroundImage
              className='h-screen w-full z-20 bg-cover'
              fluid={featuredimage?.childImageSharp.fluid}
            >
              <Link to={slug} className='w-full'>
                <div class='max-w-7xl h-full mx-auto px-4 sm</Link>:px-6 lg:px-8 flex flex-col justify-center items-center'>
                  <div class='max-w-3xl mx-auto bg-gray-900 w-full bg-opacity-30'>
                    <div class='flex flex-col justify-center items-center'>
                      <p class='text-4xl text-white font-bold text-center'>
                        {title}
                      </p>
                      <p class='text-xl text-white font-bold text-center'>
                        {author}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </BackgroundImage>
          </div>
          <div className='w-1/4 flex justify-center'>
            <ButtonNext>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='w-12 h-12 text-white'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M14 5l7 7m0 0l-7 7m7-7H3'
                />
              </svg>
            </ButtonNext>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default Post;
