import React, { useEffect, useContext } from "react";
import { getSectionPoetry } from "../utils/poetry";
import { getSectionColor } from "../utils/color";
import styles from "../styles/section.module.css";
import cx from "classnames";
import { InView } from "react-intersection-observer";
import { SectionContext } from "../utils/sectionContext.js";
import { useDencrypt } from "use-dencrypt-effect";

import { CarouselProvider, Slider } from "pure-react-carousel";

import Post from "./Post";

/**
 * sectionKey : section key
 * posts : [{title, section, featuredimage(ChildImage), author, slug}]
 */
const Section = ({ posts, sectionKey }) => {
  const colors = getSectionColor(sectionKey);

  const poetry = getSectionPoetry(sectionKey);

  const { primary, secondary } = colors;

  const { result, dencrypt } = useDencrypt({
    interval: 5,
  });

  const { curPos, dispatchPos } = useContext(SectionContext);
  useEffect(() => {
    let i = 0;

    const values = [poetry.lineOne, poetry.lineTwo];

    const action = setInterval(() => {
      dencrypt(values[i]);
      i = i === values.length - 1 ? 0 : i + 1;
    }, 10000);
    return () => clearInterval(action);
  }, []);

  return (
    <InView
      as='div'
      onChange={(inView, entry) => {
        dispatchPos({
          type: "section-index",
          pageIndex: sectionKey,
        });
      }}
      threshold={0.5}
      id={sectionKey}
      key={sectionKey}
      className='flex flex-col items-center justify-center'
      style={{
        backgroundImage: `linear-gradient(to bottom, ${primary}, ${secondary})`,
      }}
    >
      <div className={cx("py-6", styles.slider)}>
        {/* Here poetry will be static and the poetry images will be here */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "33%",
            height: "33%",
          }}
        >
          <img
            alt='poetry-one'
            width='100%'
            src={poetry.photoOne}
            style={{
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            width: "33%",
            height: "33%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt='poetry-two'
            style={{
              objectFit: "contain",
            }}
            width='100%'
            src={poetry.photoTwo}
          />
        </div>
        <div className={styles.poetry} style={{ backgroundColor: secondary }}>
          <p className='text-4xl arial italic'>{result}</p>
        </div>
      </div>

      <div className='w-full min-h-screen flex relative py-6'>
        {/* Here poetry will be animated in the background */}
        <div className={cx(styles.bgPoetry, "poetryWithAnimation")}>
          <p style={{ fontSize: "200px" }}>{poetry.roman}</p>
          <p style={{ fontSize: "200px" }}>{poetry.roman}</p>
          <p style={{ fontSize: "200px" }}>{poetry.roman}</p>
        </div>
        <CarouselProvider
          totalSlides={posts.length}
          isPlaying={true}
          infinite={true}
          isIntrinsicHeight={true}
          className='w-full'
        >
          <Slider>
            {posts.map((post, index) => {
              return <Post post={post} index={index}></Post>;
            })}
          </Slider>
        </CarouselProvider>
      </div>
    </InView>
  );
};

export default Section;
